import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import config from '~/utils/config'
import AD_FOX_CONSTANTS from '~/types/enum/AdFoxEnum'

@Module({
  name: 'advertising',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _isActive: Boolean = false
  // #region actions
  @Action({ rawError: config.rawError })
  async addInfoInContainer (id: string) {
    await this.checkAdvStatus()
    let p1 = ''
    switch (id) {
      case AD_FOX_CONSTANTS.inCatalog:
        p1 = 'dbqlc'
        break
      case AD_FOX_CONSTANTS.inBasket:
        p1 = 'dbqld'
        break
      case AD_FOX_CONSTANTS.inOrderStep:
        p1 = 'dbqlf'
        break
      case AD_FOX_CONSTANTS.inPersonal:
        p1 = 'dbqle'
        break
    }
    if (!this.isActive) { return }
    window.yaContextCb?.push(() => {
      // eslint-disable-next-line no-undef
      Ya.adfoxCode.create({
        ownerId: 5202103,
        containerId: id,
        params: {
          p1,
          p2: 'ixfw'
        }
      })
    })
    console.log(window.yaContextCb)
  }
  // #endregion

  @Action({ rawError: config.rawError })
  async checkAdvStatus () {
    const status = (await this.store.$api.getDpdStatus()).data
    if (status) {
      return this.setIsActive(status)
    }
  }

  @Mutation
  setIsActive (status: Boolean) {
    this._isActive = status
  }

  get isActive () {
    return this._isActive
  }
}
