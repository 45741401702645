import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { NuxtApp } from '@nuxt/types/app'
import GeneralListType from '~/types/enum/GeneralListType'
import config from '~/utils/config'
import s from '~/utils/s'
import { SfSection } from '~/types/api/SfSection'
import { PopupName } from '~/components/general/popups/Popup'

const popupList: string[] = [
  'RegionPopup',
  'PickupSelectorPopup',
  'FilterPopup',
  'OrderBasketPopup',
  'CallbackPopup',
  'OrderRatingPopup',
  'WaitingListPopup',
  'SmartBannerPopup',
  'ReviewPopup',
  'AuthPopup',
  'OrderAuthPopup',
  'PhoneConfirmationPopup',
  'ResultToCartPopup',
  PopupName.popupEsiaAuth
]

@Module({
  name: 'general',
  stateFactory: true,
  namespaced: true
})
export default class extends VuexModule {
  _sections: SfSection[] = []
  _isLoaded: boolean = false
  _popupName: string | null = null
  _popupOffset: number = 0
  _generalListType: GeneralListType = GeneralListType.None
  _host?: string
  _isShowTraining: boolean = false
  _isShowChoiceCity: boolean = false
  _siteClosed: boolean = false
  _sectionClosed: boolean = false
  _regionClosed: boolean = false
  _isDevelopment: boolean = false
  _codeGenerationCooldownTime: number = 30000

  @Mutation
  setSections (sections: SfSection[]) {
    this._sections = sections
  }

  @Mutation
  setHost (host: string) {
    this._host = host
  }

  @Mutation
  enableLoader () {
    this._isLoaded = process.client
  }

  @Mutation
  disableLoader () {
    this._isLoaded = false
  }

  @Mutation
  setPopupName (name: string | null) {
    this._popupName = name
  }

  @Mutation
  setGeneralListName (type: GeneralListType) {
    this._generalListType = type
  }

  @Mutation
  closeGeneralList () {
    this._generalListType = GeneralListType.None
  }

  @Mutation
  setPopupOffset (offset: number) {
    this._popupOffset = offset
  }

  @Mutation
  setIsShowTraining (val: boolean) {
    this._isShowTraining = val
  }

  @Mutation
  setIsShowChoiceCity (val: boolean) {
    this._isShowChoiceCity = val
  }

  @Mutation
  setSiteClosed (val: boolean) {
    this._siteClosed = val
  }

  @Mutation
  setSectionClosed (val: boolean) {
    this._sectionClosed = val
  }

  @Mutation
  setRegionClosed (val: boolean) {
    this._regionClosed = val
  }

  @Mutation
  setIsDevelopment (val: boolean) {
    this._isDevelopment = val
  }

  @Mutation
  setCodeGenerationCooldownTime (val: number) {
    this._codeGenerationCooldownTime = val * 1000
  }

  @Action({ rawError: config.rawError })
  async initSections () {
    const sections = await this.store.$api.getCatalogSections(
      s(this.store).regions.regionId ?? '0',
      s(this.store).regions.section?.sectionId ?? '0'
    )
    if (sections.code === 200 && sections.data) {
      this.setSections(sections.data)
    }
  }

  @Action({ rawError: config.rawError })
  openPopup (name: string) {
    this.setPopupOffset(window.window.scrollY)
    this.setPopupName(name)
    window.scrollTo({ top: 0, left: 0 })
  }

  @Action({ rawError: config.rawError })
  showPopup (name: string) {
    if (popupList.includes(name)) {
      this.openPopup(name)
    } else {
      this.closePopup()
    }
  }

  @Action({ rawError: config.rawError })
  closePopup () {
    this.setPopupName(null)
    requestAnimationFrame(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'auto' })
    })
    this.setPopupOffset(0)
  }

  @Action({ rawError: config.rawError })
  showGeneralList (type: GeneralListType) {
    if (window.innerWidth < 1201) {
      this.setGeneralListName(type)
    } else {
      this.closeGeneralList()
    }
  }

  @Action({ rawError: config.rawError })
  async fillData () {
    if (!this._siteClosed) {
      this.enableLoader()
      const sections = await this.store.$api.getCatalogSections(s(this.store).regions.regionId ?? '0',
        s(this.store).regions.section?.sectionId ?? '0')

      if (sections.code === 200 && sections.data) {
        this.setSections(sections.data)
      }

      // await s(this.store).regions.fillBasketRegionCounts()
      await Promise.all([s(this.store).basket.fillData(),
        s(this.store).wishlist.updateIds(),
        s(this.store).userProductList.loadLightweightUserList(),
        s(this.store).waitingList.fillData()])

      this.disableLoader()
    }
  }

  @Action({ rawError: config.rawError })
  redirectFatal () {
    const app = this.store.$router.app as NuxtApp
    app?.context?.error({ statusCode: 500, message: 'Internal Server Error' })
  }

  @Action({ rawError: config.rawError })
  redirectNotFound () {
    const app = this.store.$router.app as NuxtApp
    app.context.error({ statusCode: 404, message: 'Not Found' })
  }

  @Action({ rawError: config.rawError })
  redirectMainPage () {
    const app = this.store.$router.app as NuxtApp
    app.context.redirect(301, '/')
  }

  @Action({ rawError: config.rawError })
  redirectClosed () {
    this.setSiteClosed(true)
    const app = this.store.app
    if (app !== null) {
      app.context.error({ statusCode: 503, message: 'Site Closed' })
    }
  }

  @Action({ rawError: config.rawError })
  async getCodeGenerationCooldownTime () {
    const result = await this.store.$api.getCodeGenerationCooldownTime()
    if (result) {
      this.setCodeGenerationCooldownTime(result.data ?? 30)
    } else {
      this.setCodeGenerationCooldownTime(30)
    }
  }

  get popupOffset (): number {
    return this._popupOffset
  }

  get sections (): SfSection[] {
    return this._sections
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  get popupName (): string | null {
    return this._popupName
  }

  get generalListType (): GeneralListType {
    return this._generalListType
  }

  get host (): string | undefined {
    return this._host
  }

  get isShowTraining (): boolean {
    return this._isShowTraining
  }

  get isShowChoiceCity (): boolean {
    return this._isShowChoiceCity
  }

  get siteClosed (): boolean {
    return this._siteClosed
  }

  get sectionClosed (): boolean {
    return this._sectionClosed
  }

  get regionClosed (): boolean {
    return this._regionClosed
  }

  get isDevelopment (): boolean {
    return this._isDevelopment
  }

  get codeGenerationCooldownTime (): number {
    return this._codeGenerationCooldownTime
  }
}
